import React, { Fragment, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import FormInput from "../../../components/FormInput/FormInput";

import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { updateProgramName } from "../../../actions/program.actions";
import { fetchAllSubmissionStatuses } from "../../../actions/submissions.actions";
import {
  createProgram,
  fetchPrivileges,
} from "../../../actions/workspaces.actions";
import FormSelect from "../../../components/FormSelect/FormSelect";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown/MultiSelectDropdown";
import translate from "../texts";

function ProgramSettingsModal({ modalActive, setModalActive, createNew }) {
  const dispatch = useDispatch();
  const { programId } = useParams();
  const history = useHistory();

  const programData = useSelector((state) => state.program.program);

  const form = useForm({
    defaultValues: {
      programName: "",
      enable_program_integration: true,
      enable_funnel_integration: false,
      enable_submission_integration: false,
      external_url: "",
      allow_user_update_submission: true,
      allow_user_delete_submission: true,
      user_update_filter: [{ filterBy: "", operator: "", value: "" }],
      user_delete_filter: [{ filterBy: "", operator: "", value: "" }],
      enabledFields: [],
      submissionUniqueIdentifier: "",

      exactMatchCheck: false,
      imageQualityCheck: false,
      videoAnalysis: false,
      ImageBlackScreenCheck: false,
      exactMatch_fields: [{ fields: [], options: { duplicationTypes: [] } }],
      aiImageQualityCheckFields: [],
      aiVideoAnalysisFields: [],
      imageBlackScreenCheckFields: [],
    },
    criteriaMode: "all",
  });

  const {
    register,
    handleSubmit,
    watch,
    unregister,
    reset,
    formState: { errors },
    control,
    setValue,
  } = form;

  const {
    fields: updateFilterFields,
    // append: updateAppend,
    // remove: updateRemove,
  } = useFieldArray({
    control,
    name: "user_update_filter",
  });

  const {
    fields: deleteFilterFields,
    // append: deleteAppend,
    // remove: deleteRemove,
  } = useFieldArray({
    control,
    name: "user_delete_filter",
  });

  const {
    fields: exactMatchFields,
    append: exactMatchAppend,
    remove: exactMatchRemove,
  } = useFieldArray({
    control,
    name: "exactMatch_fields",
  });

  const externalStatuses = useSelector(
    (state) => state.submissions.allExternalSubmissionStatuses
  );

  const filterByOptions = [
    {
      label: "external status",
      value: "externalStatus",
    },
    {
      label: "always",
      value: "always",
    },
  ];

  const operatorOptions = [
    {
      label: "is",
      value: "=",
    },
    {
      label: "not equal",
      value: "!=",
    },
  ];

  const duplicationTypes = [
    { label: "duplicated in submission", value: "duplicated_in_submission" },
    { label: "duplicated in field", value: "duplicated_in_field" },
    { label: "duplicated in program", value: "duplicated_in_program" },
    { label: "duplicated in system", value: "duplicated_in_system" },
    { label: "duplicated in workspace", value: "duplicated_in_workspace" },
  ];

  const onSubmit = (data) => {
    const updateFilter = data.user_update_filter
      ?.map((filter) =>
        filter.filterBy === "externalStatus"
          ? [filter.filterBy, filter.operator, filter.value]
          : null
      )
      .filter((filter) => filter !== null);

    const deleteFilter = data.user_delete_filter
      ?.map((filter) =>
        filter.filterBy === "externalStatus"
          ? [filter.filterBy, filter.operator, filter.value]
          : null
      )
      .filter((filter) => filter !== null);

    const newProgramData = {
      program: {
        name: data.programName,
        allow_user_update_submission: data.allow_user_update_submission,
        allow_user_delete_submission: data.allow_user_delete_submission,
        user_update_filter: updateFilter,
        user_delete_filter: deleteFilter,

        submissionUniqueIdentifier: data.submissionUniqueIdentifier,

        integration_settings: {
          enable_program_integration: data.enable_program_integration,
          enable_funnel_integration: data.enable_funnel_integration,
          enable_submission_integration: data.enable_submission_integration,
          external_url: data.external_url,
          program_field_ids: data.enabledFields,
        },
        analysisConfig: {
          exactMatchCheck: data.exactMatch_fields,
          imageQualityCheck: [
            {
              fields: data.aiImageQualityCheckFields,
            },
          ],
          videoAnalysis: [
            {
              fields: data.aiVideoAnalysisFields,
            },
          ],
          imageBlackScreen: [
            {
              fields: data.imageBlackScreenFields,
            },
          ],
        },
      },
    };

    if (!createNew) {
      dispatch(updateProgramName(programId, newProgramData)).then(() => {
        toast(translate("update_program_data_success_msg"));
        setModalActive(false);
      });
    } else {
      delete newProgramData.program.integration_settings.program_field_ids;

      dispatch(createProgram(newProgramData.program)).then((response) => {
        toast(translate("Program created successfully"));
        setModalActive(false);

        dispatch(fetchPrivileges()).then(() => {
          const newlyCreatedProgram = response.value.data.program;
          history.push(
            `/dashboard/programs/${newlyCreatedProgram.id}/overview`
          );
        });
      });
    }
  };

  const updateAllowed = watch("allow_user_update_submission");
  const deleteAllowed = watch("allow_user_delete_submission");

  const exactMatchCheck = watch("exactMatchCheck");
  const imageQualityCheck = watch("imageQualityCheck");
  const videoAnalysisCheck = watch("videoAnalysis");
  const imageBlackScreenCheck = watch("imageBlackScreen");

  useEffect(() => {
    if (!updateAllowed) {
      unregister("user_update_filter");
    }

    if (!deleteAllowed) {
      unregister("user_delete_filter");
    }

    if (!exactMatchCheck) {
      unregister("exactMatch_fields");
    }

    if (!imageQualityCheck) {
      unregister("aiImageQualityCheckFields");
    }

    if (!videoAnalysisCheck) {
      unregister("aiVideoAnalysisFields");
    }

    if (!imageBlackScreenCheck) {
      unregister("imageBlackScreenFields");
    }
  }, [
    updateAllowed,
    deleteAllowed,
    exactMatchCheck,
    imageQualityCheck,
    videoAnalysisCheck,
    imageBlackScreenCheck,
  ]);

  useEffect(() => {
    if (!createNew && programData) {
      reset({
        programName: programData.name,
        enable_program_integration:
          programData.integrationSettings?.enableProgramIntegration,
        enable_funnel_integration:
          programData.integrationSettings?.enableFunnelIntegration,
        enable_submission_integration:
          programData.integrationSettings?.enableSubmissionIntegration,
        external_url: programData.integrationSettings?.externalUrl,
        allow_user_update_submission: programData.allowUserUpdateSubmission,
        allow_user_delete_submission: programData.allowUserDeleteSubmission,
        enabledFields: programData.integrationSettings?.programFieldIds,
        submissionUniqueIdentifier: programData.submissionUniqueIdentifier,
        user_update_filter:
          programData.userUpdateFilter?.length > 0
            ? programData.userUpdateFilter?.map((filter) => ({
                filterBy: filter[0] || "",
                operator: filter[1] || "",
                value: filter[2] || "",
              }))
            : [{ filterBy: "", operator: "", value: "" }],
        user_delete_filter:
          programData.userDeleteFilter?.length > 0
            ? programData.userDeleteFilter?.map((filter) => ({
                filterBy: filter[0] || "",
                operator: filter[1] || "",
                value: filter[2] || "",
              }))
            : [{ filterBy: "", operator: "", value: "" }],

        exactMatch_fields: programData.analysisConfig?.exactMatchCheck,
        exactMatchCheck:
          Array.isArray(programData?.analysisConfig?.exactMatchCheck) &&
          programData?.analysisConfig?.exactMatchCheck.length > 0,

        imageQualityCheck:
          Array.isArray(programData?.analysisConfig?.imageQualityCheck) &&
          programData?.analysisConfig?.imageQualityCheck[0]?.fields?.length > 0,
        aiImageQualityCheckFields: Array.isArray(
          programData?.analysisConfig?.imageQualityCheck
        )
          ? programData.analysisConfig?.imageQualityCheck[0]?.fields
          : null,

        videoAnalysis:
          Array.isArray(programData?.analysisConfig?.videoAnalysis) &&
          programData?.analysisConfig?.videoAnalysis[0]?.fields?.length > 0,
        aiVideoAnalysisFields: Array.isArray(
          programData?.analysisConfig?.videoAnalysis
        )
          ? programData.analysisConfig?.videoAnalysis[0]?.fields
          : null,

        imageBlackScreen:
          Array.isArray(programData?.analysisConfig?.imageBlackScreen) &&
          programData?.analysisConfig?.imageBlackScreen[0]?.fields?.length > 0,
        imageBlackScreenFields: Array.isArray(
          programData?.analysisConfig?.imageBlackScreen
        )
          ? programData.analysisConfig?.imageBlackScreen[0]?.fields
          : null,
      });
    }
  }, [programData]);

  useEffect(() => {
    dispatch(fetchAllSubmissionStatuses());
  }, []);

  if (!modalActive) return null;
  return (
    <div className="funnels_settings z-40 px-40 py-20">
      <div className="bg-white rounded-2xl py-14 px-16 relative">
        <button
          type="button"
          className="absolute top-4 right-4"
          onClick={() => setModalActive(false)}
        >
          <svg
            className="w-6 h-6 text-gray-400 hover:text-gray-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M10 1.667a8.333 8.333 0 100 16.666A8.333 8.333 0 0010 1.667zM9.167 9.167L5.833 5.833l.834-.834L10 8.333l3.333-3.334.834.834L10.833 9.167l3.334 3.333-.834.834L10 10.833l-3.333 3.334-.834-.834L9.167 9.167z"
            />
          </svg>
        </button>

        <h2 className="text-2xl font-bold border-b mb-10 pb-4">
          {createNew ? translate("New Program") : translate("Edit Program")}
        </h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            label={translate("Program Name")}
            {...register("programName", { required: true })}
          />
          <div className="text-red-500 -mt-4">
            {errors.programName && <span>{translate("requiredField")}</span>}
          </div>

          <div className="my-8 border-b border-gray-100" />

          <section className="space-y-4">
            <h3 className="text-xl font-bold">
              {translate("Integration Settings")}
            </h3>

            <div className="flex items-center gap-2 flex-grow flex-shrink-0">
              <input
                id="enable_program_integration"
                type="checkbox"
                {...register("enable_program_integration")}
              />
              <label htmlFor="enable_program_integration">
                {translate("Allow program")}
              </label>
            </div>

            {watch("enable_program_integration") && (
              <FormInput
                label={translate("External Integration URL")}
                {...register("external_url", {
                  required: true,
                })}
              />
            )}
          </section>

          <div className="my-8 border-b border-gray-100" />

          <section className="space-y-3">
            <div className="flex items-center gap-4 w-full bg-gray-50 p-3">
              <div className="flex items-center gap-2 flex-grow flex-shrink-0">
                <input
                  id="allow_user_update_submission"
                  type="checkbox"
                  {...register("allow_user_update_submission")}
                />
                <label htmlFor="allow_user_update_submission">
                  {translate("Allow submission update")}
                </label>
              </div>

              <div className="flex-grow flex-shrink-0 flex gap-3">
                {updateAllowed &&
                  updateFilterFields.map((field, index) => (
                    <Fragment key={field.id}>
                      <div className="flex-auto">
                        <FormSelect
                          label={translate("Filter By")}
                          options={filterByOptions}
                          defaultValue={watch(
                            `user_update_filter.${index}.filterBy`
                          )}
                          register={register(
                            `user_update_filter.${index}.filterBy`
                          )}
                        />
                      </div>
                      {watch(`user_update_filter.${index}.filterBy`) ===
                        "externalStatus" && (
                        <>
                          <div className="flex-auto">
                            <FormSelect
                              label={translate("operator")}
                              placeholder={translate("operator")}
                              options={operatorOptions}
                              defaultValue={watch(
                                `user_update_filter.${index}.operator`
                              )}
                              register={register(
                                `user_update_filter.${index}.operator`
                              )}
                            />
                          </div>
                          <div className="flex-auto">
                            <FormSelect
                              label={translate("value")}
                              placeholder={translate("value")}
                              options={externalStatuses.map((status) => ({
                                label: status?.value ?? status,
                                value: status?.value ?? status,
                              }))}
                              defaultValue={watch(
                                `user_update_filter.${index}.value`
                              )}
                              register={register(
                                `user_update_filter.${index}.value`
                              )}
                            />
                          </div>
                        </>
                      )}
                    </Fragment>
                  ))}
              </div>
            </div>

            <div className="flex items-center gap-4 w-full bg-gray-50 p-3">
              <div className="flex items-center gap-2 flex-grow flex-shrink-0">
                <input
                  id="allow_user_delete_submission"
                  type="checkbox"
                  {...register("allow_user_delete_submission")}
                />
                <label htmlFor="allow_user_delete_submission">
                  {translate("Allow submission delete")}
                </label>
              </div>
              <div className="flex-grow flex-shrink-0 flex gap-3">
                {deleteAllowed &&
                  deleteFilterFields.map((field, index) => (
                    <Fragment key={field.id}>
                      <div className="flex-auto">
                        <FormSelect
                          label={translate("Filter By")}
                          options={filterByOptions}
                          defaultValue={watch(
                            `user_delete_filter.${index}.filterBy`
                          )}
                          register={register(
                            `user_delete_filter.${index}.filterBy`
                          )}
                        />
                      </div>

                      {watch(`user_delete_filter.${index}.filterBy`) ===
                        "externalStatus" && (
                        <>
                          <div className="flex-auto">
                            <FormSelect
                              label={translate("operator")}
                              placeholder={translate("operator")}
                              options={operatorOptions}
                              defaultValue={watch(
                                `user_delete_filter.${index}.operator`
                              )}
                              register={register(
                                `user_delete_filter.${index}.operator`
                              )}
                            />
                          </div>

                          <div className="flex-auto">
                            <FormSelect
                              label={translate("value")}
                              placeholder={translate("value")}
                              options={externalStatuses.map((status) => ({
                                label: status?.value ?? status,
                                value: status?.value ?? status,
                              }))}
                              defaultValue={watch(
                                `user_delete_filter.${index}.value`
                              )}
                              register={register(
                                `user_delete_filter.${index}.value`
                              )}
                            />
                          </div>
                        </>
                      )}
                    </Fragment>
                  ))}
              </div>
            </div>
          </section>

          <div className="my-8 border-b border-gray-100" />

          {!createNew && (
            <>
              <section className="">
                <h3 className="text-xl font-bold">
                  {translate("Enabled Fields")}
                </h3>

                <div className="mt-4">
                  <MultiSelectDropdown
                    options={programData.programFields.map((field) => ({
                      label: field.name,
                      value: field.id,
                    }))}
                    selectedValue={watch("enabledFields").map((fieldId) => ({
                      label: programData.programFields.find(
                        (field) => field.id === fieldId
                      ).name,
                      value: fieldId,
                    }))}
                    onSelected={(selectedList, selectedItem) => {
                      setValue(
                        "enabledFields",
                        selectedList.map((item) => item.value)
                      );
                    }}
                    placeholder={"choose fields"}
                  />
                </div>
              </section>

              <div className="my-8 border-b border-gray-100" />

              <section className="my-8">
                <h3 className="text-xl font-bold">
                  {translate("Submission Unique Identifier")}
                </h3>

                <div className="mt-4">
                  <FormSelect
                    label={translate("Unique Identifier")}
                    options={[
                      { label: translate("None"), value: null },
                      ...programData.programFields.map((field) => ({
                        label: field.name,
                        value: field.name,
                      })),
                    ]}
                    defaultValue={programData.submissionUniqueIdentifier}
                    register={register("submissionUniqueIdentifier")}
                  />
                </div>
              </section>
            </>
          )}

          <div className="my-8 border-b border-gray-100" />

          <section className="space-y-4">
            <h2 className="text-xl font-bold">
              {translate("AI Analysis Config")}
            </h2>

            <div>
              <label className="flex items-center gap-4">
                <input type="checkbox" {...register("exactMatchCheck")} />
                {translate("Exact Match Check")}
              </label>
              {exactMatchCheck && (
                <div className="ml-7 mt-2 space-y-4">
                  {exactMatchFields.map((_, index) => (
                    <Fragment key={index}>
                      <div className="bg-slate-50 p-3">
                        <MultiSelectDropdown
                          options={programData.programFields.map((field) => ({
                            label: field.name,
                            value: field.id,
                          }))}
                          selectedValue={watch(
                            `exactMatch_fields.${index}.fields`
                          )?.map((fieldId) => ({
                            label: programData.programFields.find(
                              (field) => field.id === fieldId
                            ).name,
                            value: fieldId,
                          }))}
                          onSelected={(selectedList) => {
                            setValue(
                              `exactMatch_fields.${index}.fields`,
                              selectedList.map((item) => item.value)
                            );
                          }}
                          placeholder={translate("choose fields")}
                        />
                        <div className="mt-3">
                          <MultiSelectDropdown
                            options={duplicationTypes}
                            selectedValue={watch(
                              `exactMatch_fields.${index}.options.duplicationTypes`
                            )?.map((type) => ({
                              label: duplicationTypes.find(
                                (duplicationType) =>
                                  duplicationType.value === type
                              ).label,
                              value: type,
                            }))}
                            onSelected={(selectedList) => {
                              setValue(
                                `exactMatch_fields.${index}.options.duplicationTypes`,
                                selectedList.map((item) => item.value)
                              );
                            }}
                            placeholder={translate("Duplication Types")}
                          />
                        </div>
                      </div>
                    </Fragment>
                  ))}

                  <div className="flex gap-4">
                    <button
                      type="button"
                      onClick={() =>
                        exactMatchAppend({
                          fields: [],
                          options: { duplicationTypes: [] },
                        })
                      }
                      className="text-white bg-[#e26042] px-5 py-1 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {translate("add group")}
                    </button>

                    {exactMatchFields.length > 1 && (
                      <button
                        type="button"
                        onClick={() =>
                          exactMatchRemove(exactMatchFields.length - 1)
                        }
                        className="text-white bg-[#e26042] px-5 py-1 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        {translate("remove group")}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div>
              <label className="flex items-center gap-4">
                <input type="checkbox" {...register("imageQualityCheck")} />
                {translate("Image Quality Check")}
              </label>
              {imageQualityCheck && (
                <div className="ml-7 mt-2 mb-7">
                  <div>
                    <MultiSelectDropdown
                      options={programData.programFields.map((field) => ({
                        label: field.name,
                        value: field.id,
                      }))}
                      selectedValue={watch("aiImageQualityCheckFields")?.map(
                        (fieldId) => ({
                          label: programData.programFields.find(
                            (field) => field.id === fieldId
                          ).name,
                          value: fieldId,
                        })
                      )}
                      onSelected={(selectedList, selectedItem) => {
                        setValue(
                          "aiImageQualityCheckFields",
                          selectedList.map((item) => item.value)
                        );
                      }}
                      placeholder={translate("choose fields")}
                    />
                  </div>
                </div>
              )}
            </div>

            <div>
              <label className="flex items-center gap-4">
                <input type="checkbox" {...register("videoAnalysis")} />
                {translate("Video Analysis")}
              </label>
              {videoAnalysisCheck && (
                <div className="ml-7 mt-2 mb-7">
                  <div>
                    <MultiSelectDropdown
                      options={programData.programFields.map((field) => ({
                        label: field.name,
                        value: field.id,
                      }))}
                      selectedValue={watch("aiVideoAnalysisFields")?.map(
                        (fieldId) => ({
                          label: programData.programFields.find(
                            (field) => field.id === fieldId
                          ).name,
                          value: fieldId,
                        })
                      )}
                      onSelected={(selectedList, selectedItem) => {
                        setValue(
                          "aiVideoAnalysisFields",
                          selectedList.map((item) => item.value)
                        );
                      }}
                      placeholder={translate("choose fields")}
                    />
                  </div>
                </div>
              )}
            </div>

            <div>
              <label className="flex items-center gap-4">
                <input type="checkbox" {...register("imageBlackScreen")} />
                {translate("Image Black Screen Check")}
              </label>
              {imageBlackScreenCheck && (
                <div className="ml-7 mt-2 mb-7">
                  <div>
                    <MultiSelectDropdown
                      options={programData.programFields.map((field) => ({
                        label: field.name,
                        value: field.id,
                      }))}
                      selectedValue={watch("imageBlackScreenFields")?.map(
                        (fieldId) => ({
                          label: programData.programFields.find(
                            (field) => field.id === fieldId
                          ).name,
                          value: fieldId,
                        })
                      )}
                      onSelected={(selectedList, selectedItem) => {
                        setValue(
                          "imageBlackScreenFields",
                          selectedList.map((item) => item.value)
                        );
                      }}
                      placeholder={translate("choose fields")}
                    />
                  </div>
                </div>
              )}
            </div>
          </section>

          <button className="mt-7 text-white bg-brand px-5 py-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed">
            {translate("submit")}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ProgramSettingsModal;
