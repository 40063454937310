import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { scroller } from "react-scroll";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { deleteLabelFromProgram } from "../../actions/labels.actions";
import { fetchProgram } from "../../actions/program.actions";
import * as promptMessageActions from "../../actions/prompt-message.actions";
import {
  addComment,
  addEvaluations,
  addLabel,
  addStatus,
  checkLabel,
  checkRemoveLabel,
  clearEvaluation,
  deleteSubmissions,
  fetchSubmission,
  fetchSubmissionActivities,
  firstSubmissionDidOpen,
  openFirstSubmission,
  removeLabelFromSubmission,
  updateSubmissions,
} from "../../actions/submissions.actions";
import { isImage, isPdf, isVideo } from "../../utils/file-extensions";
import { Controls, Data, Score, ScoringPane } from "./components";

import FileViewer from "../Submissions/FileViewer/FileViewer";
import "./SingleSubmission.scss";
import translate from "./texts";

const FileState = {
  currentFile: "",
  currentFileIndex: 0,
  isModalActive: false,
};

class SingleSubmission extends Component {
  // TODO: this component should have its own state slot in redux store
  constructor(props) {
    super(props);

    this.state = {
      pendingComment: "",
      scoringTabActive: !this.isAllSubmissionsTabSelected(),
      commentsTabActive: false,
      activityTabActive: false,
      previousScoresTabActive: false,
      scoresHistoryTabActive: this.isAllSubmissionsTabSelected(),
      nextBtnShow: true,
      currentIndex: 0,
      showEmptyFields: false,
      ...FileState,
      funnelScores: [],
      qWithReason: this.props.questions.map((question) =>
        question.choices
          .map((choice) => (choice.withReason ? `${choice.value}` : ""))
          .join()
      ),
    };
  }

  componentDidMount() {
    const { actions, submissionId, history, funnelId } = this.props;
    if (submissionId !== undefined) {
      actions.fetchSubmission(submissionId, funnelId);
    }

    // check if the user is coming from comment notification
    const { location } = history;
    if (location.state && location.state.fromCommentNotification) {
      // open the activity tab if the user is coming from comment notification
      this.openActivityTab(submissionId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { submissionId, actions, fetchSubmissions, funnelId, questions } =
      this.props;
    const { submissions, currentPage, itemsPerPage, totalResult } = nextProps;
    const fetchedSubmissionId = nextProps.submissionId;
    this.setState({
      nextBtnShow: true,
      funnelScores: nextProps.submission.funnelScores,
      lastPage: Math.ceil(totalResult / itemsPerPage) === currentPage,
    });

    if (fetchedSubmissionId && fetchedSubmissionId !== submissionId) {
      actions.fetchSubmission(fetchedSubmissionId, funnelId).then(
        ({
          value: {
            data: { submission },
          },
        }) => {
          const targetSubmission = submissions.find(
            (sub) => sub.id === submission.id
          );
          if (
            submission &&
            targetSubmission &&
            !isEqual(submission.funnelStatuses, targetSubmission.funnelStatuses)
          ) {
            fetchSubmissions();
          }
        }
      );
      actions.fetchSubmissionActivities(fetchedSubmissionId);
    }

    const { lastPage } = this.state;
    submissions.forEach((submission, index) => {
      if (submission.id === submissionId) {
        this.setState({ currentIndex: index });
      }
      if (
        submission.id === submissionId &&
        lastPage &&
        index + 1 === submissions.length
      ) {
        this.setState({ nextBtnShow: false });
      }
    });
    if (questions !== nextProps.questions) {
      const withReason = nextProps.questions.map((question) =>
        question.choices
          .map((choice) => (choice.withReason ? `${choice.value}` : ""))
          .join()
      );
      this.setState({ qWithReason: withReason });
    }
  }

  deleteHandler = () => {
    const {
      submissionId,
      selectedSubmissionsIds,
      actions,
      fetchSubmissions,
      funnelId,
      isAllSubmissions,
      filters,
      query,
    } = this.props;
    const { deactivatePromptMessage } = this.props.actions;

    // const ids = isAllSubmissions
    //   ? "ALL_SUBMISSION"
    //   : selectedSubmissionsIds.concat([submissionId]);

    //TODO: temp fix untill doing a fix for adding the submission to selected submissions
    //      when clicking on the table row without selecting the checkbox as this the main cause of the duplicate problem
    const ids = isAllSubmissions
      ? "ALL_SUBMISSION"
      : selectedSubmissionsIds?.length > 0
      ? selectedSubmissionsIds
      : [submissionId];

    actions.deleteSubmissions(ids, funnelId, filters, query).then(() => {
      const { deleted, messages, closeTable } = this.props;

      if (deleted) {
        toast(
          isAllSubmissions || selectedSubmissionsIds.length > 1
            ? messages[1]
            : messages[0],
          {
            toastId: messages[0] || messages[1],
          }
        );
        // TODO: Find a cleaner and faster way to update submissions state without
        // refetching them.
        closeTable();
        fetchSubmissions();
      }
    });

    deactivatePromptMessage();
  };

  delete = () => {
    const { activatePromptMessage } = this.props.actions;

    activatePromptMessage({
      title: translate("delete_message"),
      message: "",
      buttonContent: translate("delete"),
      confirmationHandler: this.deleteHandler,
      disabledButton: false,
    });
  };

  isAllSubmissionsTabSelected = () =>
    this.props.funnelId === this.props.rootFunnelId;

  openActivityTab = (submissionId) => {
    this.setState({
      commentsTabActive: false,
      activityTabActive: true,
      scoringTabActive: false,
      scoresHistoryTabActive: false,
      previousScoresTabActive: false,
    });
    this.props.actions.fetchSubmissionActivities(submissionId);
  };

  openCommentsTab = (submissionId) => {
    this.setState({
      commentsTabActive: true,
      activityTabActive: false,
      scoringTabActive: false,
      scoresHistoryTabActive: false,
      previousScoresTabActive: false,
    });
    this.props.actions.fetchSubmissionActivities(submissionId);
  };

  openScoringTab = () => {
    this.setState({
      commentsTabActive: false,
      activityTabActive: false,
      scoringTabActive: true,
      scoresHistoryTabActive: false,
      previousScoresTabActive: false,
    });
  };

  openScoresHistoryTab = () => {
    this.setState({
      commentsTabActive: false,
      activityTabActive: false,
      scoringTabActive: false,
      scoresHistoryTabActive: true,
      previousScoresTabActive: false,
    });
  };

  openPreviousScoresTab = () => {
    this.setState({
      commentsTabActive: false,
      activityTabActive: false,
      scoringTabActive: false,
      scoresHistoryTabActive: false,
      previousScoresTabActive: true,
    });
  };

  updatePendingComment = (event) => {
    const {
      target: { value },
    } = event;

    this.setState({ pendingComment: value });
  };

  addComment = () => {
    const { actions, submissionId, funnelId } = this.props;
    this.setState({ pendingComment: "" });
    actions
      .addComment(submissionId, this.state.pendingComment, funnelId)
      .then(() => {
        actions.fetchSubmissionActivities(submissionId).then(() => {
          scroller.scrollTo("ActivitiesDiv", {
            duration: 800,
            delay: 30,
            smooth: true,
            containerId: "SubmissionActivities",
          });
        });
      });
  };

  addLabel = (newLabel, firstRun = false) => {
    const {
      submissionId,
      actions,
      selectedSubmissionsIds,
      fetchSubmissions,
      isAllSubmissions,
      funnelId,
      filters,
      query,
    } = this.props;

    // const ids = isAllSubmissions
    //   ? "ALL_SUBMISSION"
    //   : selectedSubmissionsIds.concat([submissionId]);
    const ids = isAllSubmissions
      ? "ALL_SUBMISSION"
      : selectedSubmissionsIds?.length > 0
      ? selectedSubmissionsIds
      : [submissionId];

    if (ids.length > 1 && firstRun) {
      actions.checkLabel({
        ids,
        label: newLabel,
        funnelId,
        filters,
        query,
      });
      return;
    }

    actions
      .addLabel(ids, newLabel, funnelId, filters, query)
      .then(() => fetchSubmissions());

    const isExist = this.props.availableLabels.some(
      (label) => label.key === newLabel
    );
    if (!isExist) {
      this.props.availableLabels.push({ name: newLabel, key: newLabel });
    }
  };

  addStatus = (newStatus) => {
    const {
      submissionId,
      actions,
      selectedSubmissionsIds,
      fetchSubmissions,
      isAllSubmissions,
      funnelId,
      filters,
      query,
    } = this.props;

    // const ids = isAllSubmissions
    //   ? "ALL_SUBMISSION"
    //   : selectedSubmissionsIds.concat([submissionId]);
    const ids = isAllSubmissions
      ? "ALL_SUBMISSION"
      : selectedSubmissionsIds?.length > 0
      ? selectedSubmissionsIds
      : [submissionId];

    actions
      .addStatus(ids, newStatus, funnelId, filters, query)
      .then(() => fetchSubmissions());
  };

  removeLabelFromSubmission = (labelKey, firstRun = false) => {
    const {
      selectedSubmissionsIds,
      fetchSubmissions,
      actions,
      isAllSubmissions,
      submissionId,
      funnelId,
      filters,
      query,
    } = this.props;

    // const ids = isAllSubmissions
    //   ? "ALL_SUBMISSION"
    //   : selectedSubmissionsIds.concat([submissionId]);
    const ids = isAllSubmissions
      ? "ALL_SUBMISSION"
      : selectedSubmissionsIds?.length > 0
      ? selectedSubmissionsIds
      : [submissionId];

    if (ids.length > 1 && firstRun) {
      actions.checkRemoveLabel({
        ids,
        label: labelKey,
        funnelId,
        filters,
        query,
      });
      return;
    }

    actions
      .removeLabelFromSubmission(ids, labelKey, funnelId, filters, query)
      .then(() => fetchSubmissions());
  };

  deleteLabel = (labelKey) => {
    const { actions, submissionId, funnelId, fetchSubmissions } = this.props;

    actions.activatePromptMessage({
      title: `${translate("delete_label_title")} (${labelKey})${translate(
        "question_mark"
      )}`,
      message: translate("delete_label_warning"),
      confirmationHandler: () => {
        actions
          .deleteLabelFromProgram(this.props.programId, labelKey)
          .then(() => {
            actions.fetchSubmission(submissionId, funnelId);
            fetchSubmissions();
            actions.deactivatePromptMessage();
          });
      },
    });
  };

  goToNextSubmission = () => {
    const nextIndex = this.state.currentIndex + 1;
    const { currentPage } = this.props;
    const { programId, submissions, history, actions, funnelId } = this.props;
    if (!this.state.lastPage && nextIndex >= this.props.itemsPerPage) {
      actions.openFirstSubmission(submissions[0].id);
      history.push(
        `/dashboard/programs/${programId}/funnels/${funnelId}/submissions/page/${
          currentPage + 1
        }`
      );
      return;
    }

    if (nextIndex < submissions.length) {
      this.closeFileViewer();
      history.push(
        `/dashboard/programs/${programId}/funnels/${funnelId}/submissions/page/${currentPage}/${submissions[nextIndex].id}`
      );
    }
  };

  changeQuestionAnswer = ({ id: questionId, label: value, weight, reason }) => {
    const {
      actions,
      submission,
      fetchSubmissions,
      programId,
      funnelId,
      submissionId,
    } = this.props;
    const evaluation = [
      {
        questionId,
        value,
        weight,
        reason,
      },
    ];
    actions.addEvaluations(submission.id, evaluation, funnelId).catch(() => {
      actions.fetchSubmission(submissionId, funnelId);
    });
    // .then(() => {
    //   // TODO: Find a cleaner and faster way to update submissions state without
    //   // refetching them.
    //   // fetchSubmissions();
    //   actions.fetchProgram(programId);
    //   actions.updateSubmissions(submissionId, funnelId);
    // });
  };

  clearEvaluationHandler = () => {
    const {
      actions,
      submission,
      fetchSubmissions,
      programId,
      funnelId,
      actions: { deactivatePromptMessage },
    } = this.props;

    deactivatePromptMessage();

    actions.clearEvaluation(submission.id, funnelId).then(() => {
      if (this.props.messages.length) {
        toast(this.props.messages[0], {
          toastId: this.props.messages[0],
        });
      }
      fetchSubmissions();
      actions.fetchProgram(programId);
    });
  };

  clearEvaluation = () => {
    const { activatePromptMessage } = this.props.actions;

    activatePromptMessage({
      title: translate("clear_evaluation_prompt"),
      message: "",
      buttonContent: translate("clear_evaluation"),
      confirmationHandler: this.clearEvaluationHandler,
      disabledButton: false,
    });
  };

  toggleEmptyFields = () => {
    this.setState((prevState) => ({
      showEmptyFields: !prevState.showEmptyFields,
    }));
  };

  openFileViewer = (file) => {
    if (isImage(file) || isPdf(file) || isVideo(file)) {
      this.setState({ currentFile: file, isModalActive: true });
    } else {
      const url = file.toLowerCase().startsWith("http")
        ? file
        : `http://${file}`;
      window.open(url, "_blank");
    }
  };

  closeFileViewer = () => {
    this.setState({ ...FileState });
  };

  calcTotalScore = () => {
    // TODO: DRY the max score/weight calculation.
    // See `calculateMaxWeight()` in `ScoringCardContainer.jsx`.
    const { questions } = this.props;
    let total = 0;
    questions.forEach((question) => {
      if (question.choices && question.choices.length) {
        total += Math.max(...question.choices.map((choice) => choice.weight));
      }
    });

    return total;
  };

  renderFileViewer = () => (
    <FileViewer
      src={this.state.currentFile}
      isModalActive={this.state.isModalActive}
      close={this.closeFileViewer}
    />
  );

  labelOptimizer = () => {
    const { selectedSubmissionsIds, submissions } = this.props;
    const label = selectedSubmissionsIds.map((id) => {
      const selectedSubmission = submissions.find(
        (submission) => submission.id === id
      );
      return selectedSubmission && selectedSubmission.label;
    });
    return Array.from(new Set(label)).length === 1 ? label[0] : "";
  };

  statusOptimizer = () => {
    const { selectedSubmissionsIds, submissions } = this.props;
    const status = selectedSubmissionsIds.map((id) => {
      const selectedSubmission = submissions.find(
        (submission) => submission.id === id
      );
      return selectedSubmission && selectedSubmission.status;
    });
    return Array.from(new Set(status)).length === 1 ? status[0] : "";
  };

  renderSubmission = () => {
    const {
      submissionActivities,
      programId,
      submission,
      programFields,
      availableLabels,
      closeTable,
      questions,
      anySubmissionsSelected,
      selectedSubmissionCount,
      funnelId,
      isFetching,
      rootFunnelId,
      funnels,
      selectedSubmissionsIds,
      isDeleting,
      isView,
    } = this.props;
    const { data } = submission;
    let { labelKeys: label } = submission;
    label = selectedSubmissionsIds.length > 0 ? this.labelOptimizer() : label;
    const {
      scoringTabActive,
      activityTabActive,
      nextBtnShow,
      pendingComment,
      funnelScores,
      scoresHistoryTabActive,
      previousScoresTabActive,
      showEmptyFields,
      qWithReason,
      commentsTabActive,
    } = this.state;

    return (
      <div
        className="single-submission"
        id="single-submission"
        key={submission.id}
      >
        <div className="submission-info">
          <Controls
            programId={programId}
            anySubmissionsSelected={anySubmissionsSelected}
            availableLabels={availableLabels}
            label={label}
            isFetching={isFetching}
            isDeleting={isDeleting}
            closeTable={closeTable}
            addLabel={this.addLabel}
            addStatus={this.addStatus}
            deleteLabel={this.deleteLabel}
            removeLabelFromSubmission={this.removeLabelFromSubmission}
            deleteSubmission={this.delete}
            toggleEmptyFields={this.toggleEmptyFields}
            showEmptyFields={showEmptyFields}
            isView={isView}
            selectedSubmissionsIds={selectedSubmissionsIds}
          />
          {!anySubmissionsSelected && (
            <div className="hide-on-multiple-select">
              {isView && (
                <Score
                  submission={submission}
                  funnelId={funnelId}
                  rootFunnelId={rootFunnelId}
                  isFetching={isFetching}
                  calcTotalScore={this.calcTotalScore}
                />
              )}
              <Data
                submission={submission}
                programFields={programFields}
                data={data}
                isFetching={isFetching}
                openFileViewer={this.openFileViewer}
                showEmptyFields={showEmptyFields}
              />
            </div>
          )}
          {anySubmissionsSelected && (
            <div className="selected-submission-count">
              {`${translate(
                "submissions_selected"
              )} ${selectedSubmissionCount}`}
            </div>
          )}
        </div>

        {/* TODO: Create a connected container to redux
          and make it handle scoring pane functionality */}
        {isFetching ? (
          <div className="loader" />
        ) : (
          isView && (
            <div className="scoring-pane-container">
              {!anySubmissionsSelected &&
                submission &&
                funnelId &&
                submissionActivities && (
                  <ScoringPane
                    submission={submission}
                    isAllSubmissionsTabSelected={this.isAllSubmissionsTabSelected()}
                    scoringTabActive={scoringTabActive}
                    activityTabActive={activityTabActive}
                    commentsTabActive={commentsTabActive}
                    scoresHistoryTabActive={scoresHistoryTabActive}
                    previousScoresTabActive={previousScoresTabActive}
                    questions={questions}
                    evaluations={submission.evaluations}
                    submissionActivities={submissionActivities}
                    pendingComment={pendingComment}
                    nextBtnShow={nextBtnShow}
                    openScoringTab={this.openScoringTab}
                    openActivityTab={this.openActivityTab}
                    openCommentsTab={this.openCommentsTab}
                    openScoresHistoryTab={this.openScoresHistoryTab}
                    openPreviousScoresTab={this.openPreviousScoresTab}
                    changeQuestionAnswer={this.changeQuestionAnswer}
                    goToNextSubmission={this.goToNextSubmission}
                    updatePendingComment={this.updatePendingComment}
                    addComment={this.addComment}
                    funnelScores={funnelScores}
                    funnels={funnels}
                    closeFileViewer={this.closeFileViewer}
                    withReason={qWithReason}
                    clearEvaluation={this.clearEvaluation}
                    funnelId={funnelId}
                    programId={programId}
                  />
                )}
            </div>
          )
        )}
      </div>
    );
  };

  render = () => (
    <div className="single-submission-container">
      {this.state.isModalActive && this.renderFileViewer()}
      {this.renderSubmission(this.props.submission)}
    </div>
  );
}

SingleSubmission.propTypes = {
  programId: PropTypes.string.isRequired,
  rootFunnelId: PropTypes.string.isRequired,
  submission: PropTypes.object.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  deleted: PropTypes.bool.isRequired,
  messages: PropTypes.array,
  questions: PropTypes.array.isRequired,
  programFields: PropTypes.array.isRequired,
  availableLabels: PropTypes.array.isRequired,
  closeTable: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  submissionId: PropTypes.string,
  submissionActivities: PropTypes.array.isRequired,
  fetchSubmissions: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  submissions: PropTypes.array.isRequired,
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  totalResult: PropTypes.number,
  anySubmissionsSelected: PropTypes.bool.isRequired,
  selectedSubmissionsIds: PropTypes.array.isRequired,
  selectedSubmissionCount: PropTypes.number.isRequired,
  isAllSubmissions: PropTypes.bool.isRequired,
  filters: PropTypes.array,
  isFetching: PropTypes.bool,
  funnelId: PropTypes.string.isRequired,
  funnels: PropTypes.array.isRequired,
  isView: PropTypes.bool,
  query: PropTypes.object,
};

SingleSubmission.defaultProps = {
  messages: [],
  submissionId: undefined,
  currentPage: undefined,
  itemsPerPage: undefined,
  totalResult: undefined,
  filters: [],
  isFetching: false,
  isView: true,
  query: {},
};

const mapStateToProps = (state, props) => {
  const {
    submissions: submissionsState,
    program: { program },
  } = state;

  const { funnelId } = props;

  const {
    submission,
    submissionActivities,
    submissions,
    fetchHandler,
    isDeleting,
    deleted,
    messages,
  } = submissionsState;

  const { isFetching } = submission;

  const {
    questions,
    id: programId,
    rootFunnelId,
    form: { formFields },
  } = program;

  const funnelQuestions =
    funnelId === rootFunnelId
      ? questions
      : questions.filter((question) => question.funnelId === funnelId);

  return {
    submission,
    isDeleting,
    deleted,
    messages,
    submissionActivities,
    programId,
    rootFunnelId,
    submissions,
    submissionsFetchHandler: fetchHandler,
    isFetching,
    questions: funnelQuestions,
    formFields,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      checkLabel,
      checkRemoveLabel,
      addComment,
      fetchSubmission,
      fetchSubmissionActivities,
      addLabel,
      addStatus,
      addEvaluations,
      openFirstSubmission,
      firstSubmissionDidOpen,
      deleteSubmissions,
      fetchProgram,
      removeLabelFromSubmission,
      deleteLabelFromProgram,
      clearEvaluation,
      updateSubmissions,
      ...promptMessageActions,
    },
    dispatch
  ),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleSubmission);

export default container;
