import Uppy from "@uppy/core";
import React from "react";
import Uploader from "../../../components/Uploader/Uploader";
import BaseField from "./BaseField";
import translate from "./texts";

/**
 * @class FileUploader
 * @extends BaseField
 * @description File uploader component
 */
export default class FileUploader extends BaseField {
  uppy = null;

  constructor(props) {
    super(props);
    this.state = {
      file_name: "",
      uppyReady: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.initializeUppy();
  }

  componentWillUnmount() {
    if (this.uppy) {
      this.uppy.close();
    }
  }

  initializeUppy = () => {
    const { maxFileSize } = this.props.settings.validation;
    const maxSizeInByte = maxFileSize * 1000;

    this.uppy = new Uppy({
      debug: false,
      restrictions: {
        maxNumberOfFiles: 1, // Allow only one file to be uploaded
        allowedFileTypes:
          this.props.settings.validation.file_types ||
          this.props.settings.validation.fileTypes,
        maxFileSize: maxSizeInByte,
      },
      autoProceed: true,
      // check if the file size is acceptable in frontend before uploading
      onBeforeFileAdded: (currentFile) => {
        if (currentFile.data.size > maxSizeInByte) {
          alert(`${translate("You maximized the size")} ${maxFileSize / 1000}`); // eslint-disable-line no-alert
        }
      },
    });

    this.uppy.on("file-added", (file) => {
      // Reset Uppy instance when adding a new file - fix for when adding a new file then change it to prev one
      // the old file wasn't showing - this reset insure that the current file is the only one in the list
      this.resetUppy();

      this.setState({
        file_name: file.name,
      });

      this.onChange({
        target: {
          value: file.data,
        },
      });

      const submitButton = document.getElementById("submit-form");
      if (submitButton) {
        submitButton.disabled = true;
      }
    });

    this.uppy.on("file-removed", (file) => {
      this.setState({
        file_name: "",
      });

      this.onChange({
        target: {
          value: null,
        },
      });

      // Reset Uppy instance when a file is removed
      this.resetUppy();
    });

    this.uppy.on("complete", (result) => {
      const submitButton = document.getElementById("submit-form");
      if (submitButton) {
        submitButton.disabled = false;
      }
    });

    this.uppy.on("error", (error) => {
      console.error("Uppy error:", error);
    });

    this.setState({ uppyReady: true });
  };

  resetUppy = () => {
    if (this.uppy) {
      this.uppy.reset();
      this.initializeUppy();
    }
  };

  render() {
    const { id } = this.Settings;
    const { required, maxFileSize } = this.props.settings.validation;
    return (
      <div className="field">
        {this.renderLabel()}
        <input
          type="hidden"
          id={id}
          name={id}
          value={this.state.value ? this.state.value.name : ""}
          required={required}
        />
        {this.state.file_name && (
          <div>Selected file: {this.state.file_name}</div>
        )}
        {this.state.uppyReady && this.uppy && (
          <Uploader
            uppy={this.uppy}
            note={`${translate("Max size")} ${maxFileSize / 1000} MB`}
          />
        )}
        {this.renderErrors()}
      </div>
    );
  }
}
