export const FETCH_SUBMISSIONS = "FETCH_SUBMISSIONS";
export const RESET_SUBMISSIONS = "RESET_SUBMISSIONS";
export const FETCH_SUBMISSIONS_PENDING = "FETCH_SUBMISSIONS_PENDING";
export const FETCH_SUBMISSIONS_REJECTED = "FETCH_SUBMISSIONS_REJECTED";
export const FETCH_SUBMISSIONS_FULFILLED = "FETCH_SUBMISSIONS_FULFILLED";
export const SET_SORT_CONFIG = "SET_SORT_CONFIG";
export const FETCH_OPERATORS = "FETCH_OPERATORS";
export const FETCH_OPERATORS_FULFILLED = "FETCH_OPERATORS_FULFILLED";
export const FETCH_FILTERS = "FETCH_FILTERS";
export const FETCH_FILTERS_FULFILLED = "FETCH_FILTERS_FULFILLED";
export const SAVE_FILTER = "SAVE_FILTER";
export const SAVE_FILTER_FULFILLED = "SAVE_FILTER_FULFILLED";
export const DELETE_FILTER = "DELETE_FILTER";
export const DELETE_FILTER_FULFILLED = "DELETE_FILTER_FULFILLED";
export const ADD_LABEL = "ADD_LABEL";
export const ADD_LABEL_FULFILLED = "ADD_LABEL_FULFILLED";
export const ADD_STATUS = "ADD_STATUS";
export const ADD_STATUS_FULFILLED = "ADD_STATUS_FULFILLED";
export const REMOVE_SUBMISSION_LABEL = "REMOVE_SUBMISSION_LABEL";
export const REMOVE_SUBMISSION_LABEL_FULFILLED =
  "REMOVE_SUBMISSION_LABEL_FULFILLED";
export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const ADD_COMMENT_FULFILLED = "ADD_COMMENT_FULFILLED";
export const FETCH_SUBMISSION_ACTIVITIES = "FETCH_SUBMISSION_ACTIVITIES";
export const FETCH_SUBMISSION_ACTIVITIES_FULFILLED =
  "FETCH_SUBMISSION_ACTIVITIES_FULFILLED";
export const FETCH_SUBMISSION = "FETCH_SUBMISSION";
export const FETCH_SUBMISSION_PENDING = "FETCH_SUBMISSION_PENDING";
export const FETCH_SUBMISSION_REJECTED = "FETCH_SUBMISSION_REJECTED";
export const FETCH_SUBMISSION_FULFILLED = "FETCH_SUBMISSION_FULFILLED";

export const DELETE_SUBMISSION = "DELETE_SUBMISSION";
export const DELETE_SUBMISSION_PENDING = "DELETE_SUBMISSION_PENDING";
export const DELETE_SUBMISSION_REJECTED = "DELETE_SUBMISSION_REJECTED";
export const DELETE_SUBMISSION_FULFILLED = "DELETE_SUBMISSION_FULFILLED";
export const ADD_EVALUATIONS = "ADD_EVALUATIONS";
export const ADD_EVALUATIONS_FULFILLED = "ADD_EVALUATIONS_FULFILLED";
export const CLEAR_EVALUATION = "CLEAR_EVALUATION";
export const CLEAR_EVALUATION_FULFILLED = "CLEAR_EVALUATION_FULFILLED";
export const CLEAR_EVALUATION_FULFILLED_FULFILLED =
  "CLEAR_EVALUATION_FULFILLED_FULFILLED";
export const OPEN_FIRST_SUBMISSION = "OPEN_FIRST_SUBMISSION";
export const FIRST_SUBMISSION_DID_OPEN = "FIRST_SUBMISSION_DID_OPEN";
export const FETCH_HIDDEN_COLUMNS = "FETCH_HIDDEN_COLUMNS";
export const SET_FETCH_SUBMISSIONS_HANDLER = "SET_FETCH_SUBMISSIONS_HANDLER";
export const REMOVE_SUBMISSION_ASSIGNEE = "REMOVE_SUBMISSION_ASSIGNEE";

export const UPDATE_REASON = "UPDATE_REASON";
export const UPDATE_REASON_PENDING = "UPDATE_REASON_PENDING";
export const UPDATE_REASON_REJECTED = "UPDATE_REASON_REJECTED";
export const UPDATE_REASON_FULFILLED = "UPDATE_REASON_FULFILLED";

export const FETCH_FILTER_ACTION_TYPES = "FETCH_FILTER_ACTION_TYPES";
export const FETCH_FILTER_ACTION_TYPES_FULFILLED =
  "FETCH_FILTER_ACTION_TYPES_FULFILLED";
export const FETCH_FILTER_ACTION_TYPES_PENDING =
  "FETCH_FILTER_ACTION_TYPES_PENDING";
export const FETCH_FILTER_ACTION_TYPES_REJECTED =
  "FETCH_FILTER_ACTION_TYPES_REJECTED";

export const SUBMISSION_STATUSES = "SUBMISSION_STATUSES";
export const SUBMISSION_STATUSES_PENDING = "SUBMISSION_STATUSES_PENDING";
export const SUBMISSION_STATUSES_REJECTED = "SUBMISSION_STATUSES_REJECTED";
export const SUBMISSION_STATUSES_FULFILLED = "SUBMISSION_STATUSES_FULFILLED";

export const ALL_SUBMISSION_STATUSES = "ALL_SUBMISSION_STATUSES";
export const ALL_SUBMISSION_STATUSES_PENDING =
  "ALL_SUBMISSION_STATUSES_PENDING";
export const ALL_SUBMISSION_STATUSES_REJECTED =
  "ALL_SUBMISSION_STATUSES_REJECTED";
export const ALL_SUBMISSION_STATUSES_FULFILLED =
  "ALL_SUBMISSION_STATUSES_FULFILLED";

export const FETCH_SUBMISSION_HISTORY = "FETCH_SUBMISSION_HISTORY";
export const FETCH_SUBMISSION_HISTORY_PENDING =
  "FETCH_SUBMISSION_HISTORY_PENDING";
export const FETCH_SUBMISSION_HISTORY_REJECTED =
  "FETCH_SUBMISSION_HISTORY_REJECTED";
export const FETCH_SUBMISSION_HISTORY_FULFILLED =
  "FETCH_SUBMISSION_HISTORY_FULFILLED";

export const UPDATE_SUBMISSIONS = "UPDATE_SUBMISSIONS";
export const UPDATE_SUBMISSIONS_PENDING = "UPDATE_SUBMISSIONS_PENDING";
export const UPDATE_SUBMISSIONS_REJECTED = "UPDATE_SUBMISSIONS_REJECTED";
export const UPDATE_SUBMISSIONS_FULFILLED = "UPDATE_SUBMISSIONS_FULFILLED";
export const UPDATE_SUBMISSION_STATUS = "UPDATE_SUBMISSION_STATUS";

// check label
export const CHECK_LABEL = "CHECK_LABEL";
export const CHECK_LABEL_PENDING = "CHECK_LABEL_PENDING";
export const CHECK_LABEL_REJECTED = "CHECK_LABEL_REJECTED";
export const CHECK_LABEL_FULFILLED = "CHECK_LABEL_FULFILLED";

// check remove label
export const CHECK_REMOVE_LABEL = "CHECK_REMOVE_LABEL";
export const CHECK_REMOVE_LABEL_PENDING = "CHECK_REMOVE_LABEL_PENDING";
export const CHECK_REMOVE_LABEL_REJECTED = "CHECK_REMOVE_LABEL_REJECTED";
export const CHECK_REMOVE_LABEL_FULFILLED = "CHECK_REMOVE_LABEL_FULFILLED";
