import axios from "axios";
import hexRgb from "hex-rgb";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import '../../utils/dayjs"
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "uppy/dist/uppy.min.css";
import Checked from "../../components/assets/images/checked.svg";
import Loading from "../../components/assets/images/sand-clock.svg";
import Warning from "../../components/assets/images/warning.svg";
import { FormRenderer } from "../../components/FormRenderer";
import embeddedFormTranslate from "./embeddable-form-texts";
import "./EmbeddableForm.css";

export default function FormPage() {
  const [success, setsuccess] = useState(null);
  const [loading, setloading] = useState(false);
  const [notFound, setNotFound] = useState();

  const { formId } = useParams();
  const [form, setform] = useState();
  const [formData, setFormData] = useState();

  const [captchaValue, setCaptchaValue] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/forms/${formId}`)
      .then((res) => {
        setFormData(res.data);
      })
      .catch((err) => {
        setNotFound(true);
      });
  }, []);

  useEffect(() => {
    if (formData) {
      setform({
        agonSubmissionsUrl: formData.agonSubmissionsUrl,
        currentLocale: formData.currentLocale,
        formFields: JSON.parse(formData?.formFields),
        formSettings: JSON.parse(formData?.formSettings),
        hiddenFields: null,
        isFormEnabled: formData.isFormEnabled,
        programId: formData.programId,
        url: formData.url,
      });
      localStorage.setItem(
        "formSettings",
        JSON.stringify(formData?.formSettings)
      );
    }
  }, [formData]);

  // const { formFields, isFormEnabled, formSettings } = form;
  //  first change the form sharable link to 3008 instead of 3000 to link to the front end form page
  // get formId from  params in url
  //  fetch data from http://localhost:3000/#/forms/formId
  //  fetch data from http://localhost:3000/#/forms/639215556d23ea0001ef88d3
  //  pass data to form builder component - formcontainerwrapper as props
  //  formcontainerwrapper will render the form builder component hopefully
  //   this page will be rendered on route /forms/:formId

  const getBGColor = (opacity = 100) => {
    const color = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(
      form?.formSettings?.backgroundColor
    )
      ? hexRgb(form?.formSettings?.backgroundColor)
      : hexRgb("#0028B3");
    return {
      borderTop: `solid 2px rgba(${color.red},${color.green},${color.blue},${
        opacity / 100
      })`,
    };
  };

  const postSubmission = (data) => {
    if (!captchaValue) {
      return toast.error("Please complete the captcha");
    }

    const { agonSubmissionsUrl, programId, hiddenFields } = form;

    setloading(true);

    const submissionData = {
      programId,
      program_id: programId,
      ...data,
      ...hiddenFields,
    };

    const formData = new FormData();
    for (const key in submissionData) {
      formData.append(key, submissionData?.[key]);
    }
    formData.append("captcha", captchaValue);

    axios
      .post(`${process.env.REACT_APP_API_URL}/submissions/form`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return setsuccess(true);
        }
        return setsuccess(false);
      })
      .catch(() => {
        setsuccess(false);
      });
  };

  const renderMessage = () => {
    let msg = embeddedFormTranslate("loading");
    let img = Loading;

    if (success === true) {
      img = Checked;
      msg = embeddedFormTranslate("success_msg");
    }

    if (success === false) {
      img = Warning;
      msg = embeddedFormTranslate("error_msg");
    }
    return (
      <div className="message-container gap-8 max-w-md mx-auto text-center flex flex-col justify-center items-center">
        <img className="message-icon" alt={msg} src={img} />
        <p className="field-description">{msg}</p>
      </div>
    );
  };

  if (notFound) {
    return (
      <div className="message-container h-64 gap-8 max-w-md mx-auto text-center flex flex-col justify-center items-center">
        <h4 className="text-4xl">Form not found</h4>
      </div>
    );
  }

  if (form?.isFormEnabled) {
    const Fields = Object.values(form?.formFields).map((f) => {
      if (f.type.name === "StarRating") {
        f.color = form?.formSettings.backgroundColor;
      }
      return f;
    });
    return (
      <div className="em-doc">
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        {loading ? (
          renderMessage()
        ) : (
          <div
            // Implemented this way to avoid overriding from FormRenderer
            className={`em-body ${
              form?.formSettings?.rtlDirectionEnabled
                ? "right-direction"
                : "left-direction"
            }`}
            style={getBGColor()}
          >
            {form?.formSettings.logo && (
              <img
                className="logoImage"
                src={form.formSettings.logo}
                alt="logo"
              />
            )}
            <div
              dir={form?.formSettings?.rtlDirectionEnabled ? "rtl" : "ltr"}
              className="form-title"
            >
              <span>{form?.formSettings.title}</span>
            </div>

            <FormRenderer
              fields={Fields}
              onSubmit={(data) => postSubmission(data)}
              rtlDirectionEnabled={form?.formSettings?.rtlDirectionEnabled}
              submitBtnText={embeddedFormTranslate("form_submit_button")}
              setCaptchaValue={setCaptchaValue}
              showCaptcha={true}
            />
          </div>
        )}
      </div>
    );
  }

  return <div></div>;
}
